export function ssnAgeValidator(value) {
    if (value === '') {
        return true
    }
    const MIN_AGE = 18
    const dob = getDobFromSsn(value)
    const minAllowedDate = new Date()
    minAllowedDate.setFullYear(minAllowedDate.getFullYear() - MIN_AGE)

    return (minAllowedDate - dob) > 0
}

function getDobFromSsn(ssn) {
    const dob = ssn.slice(0, 6)
    const dobYearSign = ssn.slice(6, 7)
    let dobYearPrefix = '19'
    switch (dobYearSign) {
        case '+':
            dobYearPrefix = '18'
            break
        case 'A':
            dobYearPrefix = '20'
    }
    const dobYear = dobYearPrefix + dob.slice(4, 6)
    const dobMonth = dob.slice(2, 4)
    const dobDay = dob.slice(0, 2)

    return new Date(`${dobYear}-${dobMonth}-${dobDay}`);
}
