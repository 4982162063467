import initTogglePasswordVisibility from './togglePasswordVisibility.js';
import sendAjaxRequest from './helpers/sendAjaxRequest.js';
import TemplateHelper from './helpers/TemplateHelper.js';
import worldPay from './../../../../components/com_snv2/libraries/payments/WorldPay/JS/worldpay.js';
import { processPaymentVipps } from './helpers/vippsPayment.js';
import { postNL } from './helpers/postNL.js';
require('./../../sass/lp.scss');
import {ssnAgeValidator} from './ssn-validators';
import balticParcelPoints from "./balticParcelPoints";

var isLp = !!(jQuery('body').hasClass('landing-page'));
APP.validators = {};

// save user first name, last name and email taken from LP registration form
function saveUserDetailsToSession(){
    jQuery.ajax({
        type: "POST",
        async: false,
        url: "index.php?option=com_snv2&task=saveUserDetailsToSession&tmpl=component&format=json",
        dataType: 'json',
        data: {'pageType':'vista', 'firstname': jQuery('#jform_snprofile_first_name').val(), 'lastname': jQuery('#jform_snprofile_last_name').val(), 'email': jQuery('#jform_email1').val()},
        success:function(data){
            return true;
        }
    }).fail(function(){
        return true;
    });
}
function dibsEasyBindPaymentCompleted() {
	var id = jQuery('input[name="jform[id]"]').val();
	window.checkout.on('payment-completed', function (response) {
		jQuery.ajax({
			type: "POST",
			url: "index.php?option=com_snv2&task=landingpage.updatePaymentStatusForLP&tmpl=component&format=json",
			data: {paymentId: response.paymentId},
			success: function (data) {
				window.location = window.dibsConfig.redirect + '?paymentId=' + response.paymentId + '&type=dibseasy&pid=' + id + '&orderid=' + data.data;
			},
			error: function (xhr, status, error) {
				window.location = window.dibsConfig.redirect + '?paymentId=' + response.paymentId + '&type=dibseasy&pid=' + id;
			},
		});
	});
}

function managePrevNextSliderButtons(lpSlidesCount,thisSlider){
	if((lpSlidesCount - 1) === thisSlider.currentSlide){ // last slide
        jQuery('.slidesContainer .promobox__arrow--next').hide();
        jQuery('.slidesContainer .promobox__arrow--prev').show();
	}else if(thisSlider.currentSlide === 0){ // first slide
        jQuery('.slidesContainer .promobox__arrow--prev').hide();
        jQuery('.slidesContainer .promobox__arrow--next').show();
	}else{ // any other slide
        jQuery('.slidesContainer .promobox__arrow--prev').show();
        jQuery('.slidesContainer .promobox__arrow--next').show();
	}
}

var galleryActive = jQuery('.banner__inner').data('gallery');
if(galleryActive === 1){
	var pageSpeedScripts = jQuery('.banner__inner .lpslide script');
	jQuery.when(pageSpeedScripts.remove()).then(function(){
		if(document.getElementsByClassName('lpslide').length) {
			var lpslide = new Siema({
				selector: '.lpslide',
				onChange: function(){
					// show price only for first image
					if(this.currentSlide > 0 && window.outerWidth > 450) {
						jQuery('.productprice').hide(150);
					} else {
						jQuery('.productprice').show(150);
					}
					managePrevNextSliderButtons(lpSlidesCount,this);
				},
				onInit: function(){
					jQuery('.slidesContainer .promobox__arrow--prev').hide(); // hide prev button on initial display
					jQuery('.cover').removeClass('hide');
					jQuery('.promobox__arrow').removeClass('hide');
					jQuery('.productprice').removeClass('hide');
					jQuery('.galleryLoader').remove();
					managePrevNextSliderButtons(lpSlidesCount,this);
				}
			});
			var lpSlidesCount = lpslide.innerElements.length;
			jQuery('.promobox__arrow--prev').on('click', function(){
				lpslide.prev();
			});
			jQuery('.promobox__arrow--next').on('click', function(){
				lpslide.next();
			});
			var moved = false;
			jQuery('.pop').mousedown(function(){
				moved = false;
			}).mousemove(function(){
				moved = true;
			}).mouseup(function(event){
				if(!moved){
					// jQuery('.modal-dialog').css('width', jQuery(this).context.naturalWidth);
					setTimeout(function(){
						jQuery('.modal-dialog').css('width', jQuery('.imagepreview').width());
					},200);

					jQuery('.imagepreview').attr('src', jQuery(this).attr('src'));
					jQuery('.imagepreview').attr('srcset',jQuery(this).data('full-image-srcset'));
					jQuery('#imagemodal').modal('show');
					moved = false;
				}
			});
		}
	});
}

if(jQuery('.topMenu').length > 0) {
	var topMenu = jQuery('.topMenu');
	var showTopMenu = false;
	jQuery(window).on('scroll resize', function() {
	    if(jQuery(window).scrollTop() > 200 !== showTopMenu) {
	    	topMenu.slideToggle(200);
	    	showTopMenu = !showTopMenu;
	    }
	});
}

// go to CTA button
if (jQuery('[data-scroll-to="cta"]').length) {
	const $button = jQuery('[data-scroll-to="cta"]');
	$button.click(() => {
		let scroll_el = jQuery("aside");
		if (jQuery(".preform").length > 0) {
			scroll_el = jQuery(".preform");
		} else if (jQuery(".personalinfo").length > 0) {
			scroll_el = jQuery(".personalinfo");
		}
		jQuery('html, body').animate({
			scrollTop: scroll_el.offset().top - (topMenu.outerHeight() + 5)
		}, 500);
	});
}

if(jQuery('.tabs__inner ul li').length > 0){
	jQuery('.tabs__inner ul li').click(function(){
		jQuery('.tabs__inner ul li').removeClass('active');
		jQuery(this).addClass('active');
	});
}

function scrollup(e) {
	jQuery('html, body').animate({ scrollTop: 0 }, 'fast');
	e.preventDefault();
}

if(jQuery('.scrollup').length > 0) {
	jQuery('.scrollup').on('click', function(e){
		scrollup(e);
	});
}

if(jQuery('#back-top').length > 0) {
	jQuery('#back-top').on('click', function(e){
		scrollup(e);
	});
}

jQuery('#password-remind').on('click',function() {
    jQuery('#password-remind-form').toggle();
    jQuery('#sendMagicLink')
});

jQuery('.tab-content #system-readmore').each(function(e){
	var id = 'system-readmore_' + e;
	jQuery(this).nextUntil('div:last').wrapAll('<div class="dwrap'+e+'" style="display:none;" />');
	jQuery(this).remove();
	jQuery('.dwrap'+e).after('<span class="btn btn-info btn-xs click'+e+'">'+Joomla.JText._('COM_CONTENT_READ_MORE_TITLE')+'</span>');
	jQuery('.click'+e).toggle(function(){
		jQuery(this).text(Joomla.JText._('COM_MENUS_ACTION_COLLAPSE')).siblings('.dwrap'+e).show();
	}, function(){
		jQuery(this).text(Joomla.JText._('COM_CONTENT_READ_MORE_TITLE')).siblings('.dwrap'+e).hide();
	});
});

if(jQuery('html').attr('lang') === 'nl-nl') {
    jQuery('#jform_snprofile_address').parents(':eq(1)').hide();
    jQuery('#jform_snprofile_postal').parents(':eq(1)').hide();
    jQuery('#jform_snprofile_postal_code, #jform_snprofile_housenumber').on('keyup', function(){
        const postal_code = jQuery('#jform_snprofile_postal_code').val().replace(/_/g,"");
        if((postal_code.length - 1) === 6 && jQuery('#jform_snprofile_housenumber').val().length > 0){
            postNL(jQuery('#jform_snprofile_postal_code').val(), jQuery('#jform_snprofile_housenumber').val());
        }
    });
}

jQuery(window).on('load', function() {
	var cookies = document.cookie.split(';');
	if(!cookies.length){
		document.getElementById('panel_cookie_dol').style.display='none';
	}
});

jQuery('document').ready(function(){
	"use strict";
    getOmniFinanceInstallments();

    jQuery('.productview__ask').on('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(jQuery(".notifyme__form").css('display') === 'none'){
            jQuery(".notifyme__form").slideToggle();
        }
    });

    jQuery('.notifyme__close').on('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        jQuery(e.target).closest(".notifyme__form").slideUp();
    });

    jQuery('.productview__button').on('click', (e) => {
        const formSelector = '.notifyme__form';
        const $form = jQuery(formSelector);
        const token = jQuery("input[name='pId']").next().attr('name');
        let formData = {
            "name": $form.find('#jform_name').val(),
            "email": $form.find('#jform_email').val(),
            "phone": $form.find('#jform_phone').val(),
            "tos_consent": $form.find('#jform_tos_consent').is(":checked") ? 1 : 0,
            "pId": $form.find('#pId').val(),
            'recaptcha': typeof grecaptcha !== 'undefined' ? grecaptcha.getResponse() : ''
        };
        const data = 'name=' + formData.name + '&email=' + formData.email + '&phone=' + formData.phone + '&tos_consent=' + formData.tos_consent + '&pId=' + formData.pId + '&recaptcha=' + formData.recaptcha + '&' + token + '=1';

        $form.submit(function(e) {
            e.stopPropagation();
            e.preventDefault();
        }).validate({
            ignore: "",
            rules: {
                "jform[tos_consent]" : {
                    required: true
                },
                "jform[email]" : {
                    required: true,
                },
                "jform[name]" : {
                    required: true,
                    minlength: 2
                }
            },
            messages : {
                "jform[tos_consent]": {
                    required: $form.data("message-required")
                },
                "jform[email]": {
                    required: $form.data("message-required"),
                    regex: $form.data("message-required")
                },
                "jform[name]": {
                    required: $form.data("message-required"),
                    minlength: $form.data("message-required")
                }
            },
            errorClass: "has-error",
            errorPlacement: function(error, element) {
                error.insertBefore(element);
            },
            highlight: function(element, errorClass) {
                jQuery(element).parent().addClass("has-error");
            },
            submitHandler: function(form) {
                if (jQuery('#jform_captcha').length) {
                    grecaptcha.execute()
                        .then(() => {
                            if (!grecaptcha.getResponse()) {
                                return;
                            }
                            // method submitForm() is called from the captcha callback
                        }).catch((error) => {
                        console.error(error);
                    });
                } else {
                    jQuery.ajax({
                        type: 'POST',
                        url: 'index.php?option=com_snv2&task=checkout.saveToNotificationList&tmpl=component&format=json',
                        data: data,
                        cache: false,
                        beforeSend : function(){
                            $form.find('.alert').hide();
                            $form.find('button[type=submit]').prop('disabled', true).addClass('disabled');
                        }
                    }).done(data => {
                        const status = typeof data.data !== 'undefined' ? data.data.status : 'error';
                        const message = typeof data.data !== 'undefined' ? data.data.message : 'Response error';
                        $form.find('button[type=submit]').prop('disabled', false).removeClass('disabled');
                        $form.find('.alert')
                            .removeClass('alert-error, alert-success')
                            .addClass(`alert-${status}`)
                            .html(message)
                            .show();
                        if (status === 'success') {
                            $form.find('> *').not('.alert').remove();
                        }
                    });
                }
            }
        });
        jQuery.validator.addMethod('jform[email]', function (value) {
            return /.+@.+\\..+/.test(value);
        }, $form.data("message-required"));
    });

    jQuery("input[name*='_consent']").on('change', function() {
        let name = jQuery(this)[0].name.split('][');
        name = name[1].slice(0,-1);
        if(jQuery(this).val() === '0'){
            jQuery('.'+name).show()
        } else {
            jQuery('.'+name).hide()
        }
    });

	// tabs
	const tabWrapper = jQuery('.tabs-wrapper');
	let tabsWidth = 0;
	jQuery('.desktop-view .nav-tabs li').each((i, li) => {
		tabsWidth += Math.ceil(jQuery(li).width());
	});
    if (!jQuery('.nav-tabs').hasClass('nav-tabs-vertically')) {
        jQuery('.nav-tabs').css('width', tabsWidth + 1);
    }

	const scrollTime = 150;
	const maxScroll = tabsWidth - tabWrapper.width();

	// show buttons only if scroll is active
	if (maxScroll > 5) {
		jQuery('.tabs-navigate-next').show()
		jQuery('.tabs-navigate-next').on('click', () => {
			tabWrapper.animate({scrollLeft: maxScroll}, scrollTime);
			jQuery('.tabs-navigate-back').show()
			jQuery('.tabs-navigate-next').hide()
		})
		jQuery('.tabs-navigate-back').on('click', () => {
			tabWrapper.animate({scrollLeft: 0}, scrollTime);
			jQuery('.tabs-navigate-back').hide()
			jQuery('.tabs-navigate-next').show()
		})

		let timer;
		tabWrapper.on('scroll', () => {
			if (timer) {
	            clearTimeout(timer);
	        }
	        timer = setTimeout( () => {
	        	if (tabWrapper.scrollLeft() < 5) {
	        		jQuery('.tabs-navigate-back').hide()
	        	} else {
	        		jQuery('.tabs-navigate-back').show()
	        	}
	        	if (tabWrapper.scrollLeft() > maxScroll-5) {
	        		jQuery('.tabs-navigate-next').hide()
	        	} else {
	        		jQuery('.tabs-navigate-next').show()
	        	}
	        }, 100)
		})
	}


    const alignment = jQuery('ul.product-tabs.nav-tabs-vertically').length ? 'vertically' : 'horizontally';
    const mobileViews = jQuery('.mobile-view');
    const desktopViews = jQuery('.desktop-view');

    if (alignment === 'vertically') {


        if (window.innerWidth <= 767 ) {
            mobileViews.css('display', 'flex');
            desktopViews.css('display', 'none');
            jQuery('.tab-pane').each(function(index) {
                if (index !== 0) {
                    jQuery(this).addClass('hidden');
                }
            });
        } else {
            desktopViews.css('display', 'flex');
            mobileViews.css('display', 'none');
        }

        if (window.innerWidth <= 767 ) {
            jQuery('a[data-toggle="tab"]').on('click', function(event) {
                event.preventDefault();
                let targetId = jQuery(this).attr('href').substring(1);
                let targetPane = jQuery('#' + targetId);
                let arrow = jQuery(this).find('.arrow');

                if (!targetPane.hasClass('hidden')) {
                    targetPane.addClass('hidden');
                    arrow.removeClass('down');
                } else {
                    jQuery('.tab-pane').addClass('hidden');
                    jQuery('.arrow').removeClass('down');
                    targetPane.removeClass('hidden');
                    arrow.addClass('down');
                }
            });
        } else {
            mobileViews.css('display', 'none');
        }
    }

    if (alignment === 'horizontally') {
        mobileViews.css('display', 'none');
    }

	// tabs - end

	var labelID;
	var escapeActivated = false;
	var maxWindowHeight = 0;

	var validForm = false;
	var jform = jQuery('form');
    var formId = '';

    TemplateHelper.initPasswordRemind();

    // async login form
    jQuery('#login-form').on('submit', function(e){
        e.preventDefault();
        e.stopPropagation();

        var $token = jQuery('#login-form input[type=hidden][value="1"]').last().attr('name'),
            $username = jQuery('#modlgn-username').val(),
            $password = jQuery('#modlgn-passwd').val(),
            $rememberMe = jQuery('#modlgn-remember').is(":checked"),
            data = 'username='+$username + '&password=' + $password + '&remember=' + $rememberMe + '&' + $token + '=1';


        jQuery.ajax({
            type: "POST",
            async: false,
            url: "index.php?option=com_snv2&task=vista.asyncLogin&tmpl=component&format=json",
            dataType: 'json',
            data: data,
            success:function(data){
                if(data.success === true && data.data !== ''){
                    if(data.data === true){
                        // login succesfull
                        jQuery('#loginFormModal').modal('toggle');
                        location.reload(true);
                    }else{
                        // login failed
                        jQuery('.alertTargetContainer').html('');
                        jQuery('.alertTempContainer .alert-message').html(data.messages.warning[0]);
                        jQuery('.alertTempContainer').children().clone().appendTo('.alertTargetContainer');
                        jQuery('.alertTargetContainer .alert').slideDown();
                    }

                }
            }
        }).fail(function(){
            // submit form the standard way
            jQuery('#login-form').unbind('submit').submit();
        });
    });

    jQuery('.addToCartBtn').click(function(e) {
		e.preventDefault();
		e.stopPropagation();
		if( ['fi-fi','sv-se'].includes(jQuery('html').attr('lang')) ) {
			if(jQuery('#jform_snprofile_ssn_1').length) {
				if(jQuery('#payment_invoice').prop('checked') || jQuery('#payment_online').prop('checked')) {

					jQuery('#jform_snprofile_ssn_1').rules('remove');
				    if(jQuery('html').attr('lang') === 'fi-fi') {
                        jQuery('#jform_snprofile_ssn_1').rules( 'add', {
                                required: !!jQuery('#payment_invoice').prop('checked'),
                                snssnFI : true,
                                underage: true,
                                messages : {
                                    snssnFI : jQuery('#jform_snprofile_ssn_0').data('regexp'),
                                    underage: Joomla.JText._('COM_SNV2_SSN_CODE_UNDERAGE')
                                },
                            }
                        );
                    }
                    if(jQuery('html').attr('lang') === 'sv-se') {
                        jQuery('#jform_snprofile_ssn_1').rules( 'add', {
                                required: false,
                                snssnSE : true,
                                messages : {
                                    snssnSE : jQuery('#jform_snprofile_ssn_0').data('regexp'),
                                },
                            }
                        );
                    }
				} else {
					jQuery('#jform_snprofile_ssn_1').rules('remove');
					jQuery('#jform_snprofile_ssn_1').removeAttr('required');
					jQuery('#jform_snprofile_ssn_1').removeClass('required');
					var validator = jQuery("#lp-order").validate();
					validator.resetForm();
				}
			}

		}

		if(jQuery('#member-registration').length) {
			validForm = jQuery('#member-registration').valid();
			jform = jQuery('#member-registration');
            formId = 'member-registration';
		}

		if(jQuery('#lp-order').length) {
			validForm = jQuery('#lp-order').valid();
			jform = jQuery('#lp-order');
            formId = 'lp-order';
		}

		if(validForm) {
			window.dataLayer = window.dataLayer || [];
			dataLayer.push({
				"ecommerce": {
					"currencyCode": gtmJs.currencyCode,
					"actionField": gtmJs.detail.actionField,
					"add": {
						"products": [{
							"id": gtmJs.detail.products[0].id,
							"name": gtmJs.detail.products[0].name,
							"price": gtmJs.detail.products[0].price,
							"category": gtmJs.detail.products[0].category,
							"quantity": 1
						}]
					}
				},
				"event": "addToCart"
			});
			dataLayer.push({
				"ecommerce": {
					"checkout": {
						"actionField": {'step': 1},
						"products": [{
							"id": gtmJs.detail.products[0].id,
							"name": gtmJs.detail.products[0].name,
							"price": gtmJs.detail.products[0].price,
							"category": gtmJs.detail.products[0].category,
							"quantity": 1
						}]
					}
				},
				"event": "BillingAndDelivery"
			});
            dataLayer.push({
                "ecommerce": {
                    "checkout_option": {
                        "actionField": {
                            "step": 1,
                            "option": jQuery('body.guest').length ? "Register" : "Login"
                        }
                    }
                },
                "event": "checkOutOption"
            });
			dataLayer.push({
				"ecommerce": {
					"checkout": {
						"actionField": {'step': 2},
						"products": [{
							"id": gtmJs.detail.products[0].id,
							"name": gtmJs.detail.products[0].name,
							"price": gtmJs.detail.products[0].price,
							"category": gtmJs.detail.products[0].category,
							"quantity": 1
						}]
					}
				},
				"event": "BillingAndDelivery"
			});

            // get payment option
            var gtmPayment = '';
            // when "Payment options on first step" is enabled
            if (jQuery('input[name=payment]').length) {
                gtmPayment = jQuery('input[name=payment]:checked').val();
            } else {
                gtmPayment = gtmDefaultPayment === 'poi' ? 'invoice' : gtmDefaultPayment;
            }

            dataLayer.push({
                "ecommerce": {
                    "checkout_option": {
                        "actionField": {
                            "step": 2,
                            "option": gtmPayment
                        }
                    }
                },
                "event": "checkOutOption"
            });
		} else {
			if (jQuery('.has-error').length) {
				setTimeout(() => { // wait for jQuery validate
					TemplateHelper.scrollToInvalidField();
				}, 100);

			}
			jQuery(this).prop('disabled', false);
		}

		var paymentType = 'online';
		if(jQuery('input[name="payment"]:checked').length){
			paymentType = jQuery('input[name="payment"]:checked').val();
		} else if(jQuery('html').attr('lang') === 'sv-se') {
            paymentType = '';
        }

        if (validForm && paymentType === 'apple_google_pay') {
            e.preventDefault();
            const paymentUrl = "index.php?option=com_snv2&task=landingpage.getMonekRedirect&tmpl=component&format=json";

            const formData = jQuery(jform).find('input[name!=task]').serialize();

            fetch(paymentUrl, {
                method: "POST",
                headers: {'Content-Type':'application/x-www-form-urlencoded'},
                body: formData
            })
                .then(response => response.json())
                .then(response => {
                    if (response.success && response.data.redirect_url) {
                        window.location.href = response.data.redirect_url;
                    } else {
                        console.error("Error with the payment redirection.");
                    }
                })
                .catch(error => {
                    console.error("Fetch error: ", error);
                });

            return;
        }

        if(validForm) {
            let worldPayInstance = new worldPay('landingpage', formId);
            if(worldPayInstance.canProcessPayment()) {
                worldPayInstance.sendRequest('landingpage', 'getWorldPayIframe', formId);
                return;
            }
        }

		if(jQuery('#dibseasy__wrapper').length && validForm && paymentType === 'online') {
			jQuery.ajax({
				type: "POST",
				url: "index.php?option=com_snv2&task=landingpage.initializePayment&tmpl=component&format=json",
				data: jform.find('input[name!=task]').serialize(),
				success: function(data){
					window.dibsConfig = window.dibsConfig || JSON.parse(document.getElementById('dibseasy__wrapper').getAttribute('data-dibsconfig'));
					window.dibsConfig.paymentId = data.data;
					window.checkout = new Dibs.Checkout(dibsConfig);
					dibsEasyBindPaymentCompleted();
					jform.slideUp(500);
				},
				error: function(xhr,status,error){
					Joomla.renderMessages({"error":[data.message]});
				}
			});
			return;
		}

        if(validForm && document.getElementById('vipps-checkout-frame-container')) {
            if(jQuery('#payment_online').prop('checked')){
                processPaymentVipps(jform, jQuery('.addToCartBtn'), 'index.php?option=com_snv2&task=landingpage.initialisePaymentVippsCheckout&tmpl=component&format=json')
                return;
            }

        }

        if(paymentType === 'payment_omni_finance') {
            jform.find('input[name="omni-finance-installment-plan-modal"]').remove();
            jform.find('input[name="omni-finance-installment-code"]').remove();

            const paymentSelectValue = jQuery('.payment_omni_finance_options #financePlanSelectPayment').val();

            if (paymentSelectValue) {
                jform.append(`<input type="hidden" name="omni-finance-installment-code" value="${paymentSelectValue}">`);
            }
        }

		jform.submit();
	});


    // async password reminder form
    jQuery('#remindme-form').on('submit', function(e){
        e.preventDefault();
        e.stopPropagation();

        var $token = jQuery('#remindme-form input[type=hidden][value="1"]').last().attr('name'),
            $email = jQuery('#jform_email').val(),
            data = 'email='+$email + '&'+$token + '=1';

        jQuery.ajax({
            type: "POST",
            async: false,
            url: "index.php?option=com_snv2&task=vista.asyncResetPassword&tmpl=component&format=json",
            dataType: 'json',
            data: data,
            success:function(data){
                if(data.success === true && data.data !== ''){
                    if(data.data === true){
                        // login succesfull
                        jQuery('.emailAlertTargetContainer').html('');
                        jQuery('.alertTempContainer .alert-message').html(Joomla.JText._('COM_SNV2_PASSWORD_RESET_SUCCESS'));
                        jQuery('.alertTempContainer').children().clone().appendTo('.emailAlertTargetContainer');
                        jQuery('.emailAlertTargetContainer .alert h4').addClass('hide');
                        jQuery('.emailAlertTargetContainer .alert').slideDown();

                    }else{
                        // login failed
                        jQuery('.emailAlertTargetContainer').html('');
                        jQuery('.alertTempContainer .alert-message').html(Joomla.JText._('COM_USERS_INVALID_EMAIL'));
                        jQuery('.alertTempContainer').children().clone().appendTo('.emailAlertTargetContainer');
                        jQuery('.emailAlertTargetContainer .alert').slideDown();
                    }

                }
            }
        }).fail(function(){
            // submit form the standard way
            jQuery('#remindme-form').unbind('submit').submit();
        });
    });

	jQuery('label').click(function() {
		labelID = jQuery(this).attr('for');
		jQuery('#'+labelID).attr('selected', 'selected');
	});
	jQuery('img.zoom').attr('data-action','zoom');
	if(jQuery('#regulations_trigger').length > 0){
		jQuery('#regulations_trigger').click(function(e){
			e.preventDefault();
			e.stopPropagation();
			jQuery('.tab--regulations').trigger('click');
		});
	}

	jQuery.validator.addMethod(
		'regex',
		function(value, element, regexp) {
		    var re = new RegExp(regexp);
		    return this.optional(element) || re.test(value);
		},
		""
	);

    jQuery.validator.addMethod(
        'snssnSE',
        function(value, element, checkRegexp) {
            if(value === '') {
                return true;
            }
            var regexp = true;
            if(checkRegexp) {
                regexp = /^(19|20)?(\d{6}([-+]|\s)\d{4}|(?!19|20)\d{10})$/.test(value);
                if(!regexp) {
                    return false;
                }

            }
            var ssn = value
                .replace(/\D/g, '')     // strip out all but digits
                .split('')              // convert string to array
                .reverse()              // reverse order for Luhn
                .slice(0, 10);          // keep only 10 digits (i.e. 1979 becomes 79)
            if (ssn.length === 0) {
                return false;
            }
            var sum = ssn
                // convert to number
                .map(function(n) {
                    return Number(n);
                })
                // perform arithmetic and return sum
                .reduce(function(previous, current, index) {
                    // multiply every other number with two
                    if (index % 2) current *= 2;
                    // if larger than 10 get sum of individual digits (also n-9)
                    if (current > 9) current -= 9;
                    // sum it up
                    return previous + current;
                });
            // sum must be divisible by 10
            return 0 === sum % 10;
        }
    );

    jQuery.validator.addMethod(
        'snssnFI',
        function(value, element, checkRegexp) {
            if (value === '') {
                return true;
            }
            var regexp = true;
            if(checkRegexp) {
                var re = new RegExp('^([0-9]{6})([-+A]{1})([0-9]{3})([0-9A-Z]{1})$');
                regexp = re.test(value);
            }
            var res = value.substring(0, 6)+''+value.substring(7,10);
            var rest = res % 31;
            var controlSign = "0123456789ABCDEFHJKLMNPRSTUVWXY";
            var controlNumber = false;
            if(controlSign[rest]===value.substring(10, 11)) {
                controlNumber = true;
            }
            return (this.optional(element) || (regexp && controlNumber) );
        }
    );

    jQuery.validator.addMethod('underage', (value) => {
        return ssnAgeValidator(value)
    })

	jQuery.validator.addMethod(
		'notEqualTo',
		function(value, element, params) {
			return  (this.optional(element) || !params.includes(value))
		},
		Joomla.JText._('COM_SNV2_MOBILE_VALIDATION2_MESSAGE')
	);

	jQuery('#member-registration').on('keyup keypress', function(e) {
	var keyCode = e.keyCode || e.which;
		if (keyCode === 13) {
			e.preventDefault();
			e.stopPropagation();
			return false;
		}
	});
	var lang = jQuery('html').attr('lang');
	if(lang === 'nb-no'){
		APP.validators.registerno.validateRegisterForm(jQuery('.form-validate-update'));
	} else if(lang === 'pl-pl'){
		jQuery('#jform_snprofile_postal_code').mask('99-999');
		jQuery('#jform_snprofile_mobile').mask('999999999');
		APP.validators.registerpl.validateRegisterForm(jQuery('.form-validate-update'));
	} else if(lang === 'hu-hu'){
		APP.validators.registerhu.bindMasks();
		APP.validators.registerhu.validateRegisterForm(jQuery('.form-validate-update'));
	} else if(lang === 'cs-cz'){
		APP.validators.registercz.validateRegisterForm(jQuery('.form-validate-update'));
	} else if(lang === 'sk-sk'){
		APP.validators.registersk.validateRegisterForm(jQuery('.form-validate-update'));
	} else if(lang === 'uk-uk'){
		APP.validators.registeruk.validateRegisterForm(jQuery('.form-validate-update'));
	} else if(lang === 'ie-ie'){
		APP.validators.registerie.validateRegisterForm(jQuery('.form-validate-update'));
	} else if(lang === 'lt-lt'){
		APP.validators.registerlt.validateRegisterForm(jQuery('.form-validate-update'));
	} else if(lang === 'et-ee'){
		APP.validators.registeree.validateRegisterForm(jQuery('.form-validate-update'));
	} else if(lang === 'lv-lv' || lang === 'ru-ru'){
		APP.validators.registerlv.bindMasks();
		APP.validators.registerlv.validateRegisterForm(jQuery('.form-validate-update'));
	} else if(lang === 'da-dk'){
		APP.validators.registerdk.validateRegisterForm(jQuery('.form-validate-update'));
	} else if(lang === 'sv-se'){
		APP.validators.registerse.bindMasks();
		APP.validators.registerse.validateRegisterForm(jQuery('.form-validate-update'));
	} else if(lang === 'nl-be' || lang === 'fr-fr'){
		APP.validators.registerbe.bindMasks();
		APP.validators.registerbe.validateRegisterForm(jQuery('.form-validate-update'));
	} else if(lang === 'nl-nl'){
        APP.validators.registernl.bindMasks();
        APP.validators.registernl.validateRegisterForm(jQuery('.form-validate-update'));
    } else if(lang === 'fi-fi') {
		APP.validators.registerfi.bindMasks();
		APP.validators.registerfi.validateRegisterForm(jQuery('.form-validate-update'));
	}
	// add extra validation in longform
	if(jQuery('.snprofile').hasClass('snprofile--longform')){
		jQuery("#jform_email1").rules("add", {
	        userExists: true
	    });
	}

	// consents style
	var $body = jQuery('body');
	if($body.hasClass('hu-HU') || $body.hasClass('nl-BE') || $body.hasClass('fr-FR') || $body.hasClass('nb-NO') || $body.hasClass('uk-UK')){
		jQuery('#member-registration .control-group .controls input[type=radio]').closest('.control-group').addClass('consent-label-full');
	} else if(!$body.hasClass('lv-LV')) {
		jQuery('#jform_snprofile_general_consent-lbl').closest('.control-group').addClass('consent-label-full');
	}

	// General consent button states
	if(jQuery('input[name="jform[snprofile][general_consent]"]').length > 0){
		jQuery('input[name="jform[snprofile][general_consent]"]').change(function(){
			if(jQuery(this).attr('type') === 'checkbox' && jQuery(this).is(':checked') === true){
				jQuery("input[name*='_consent'][value='1']").prop('checked', true);
			} else if(jQuery(this).attr('type') === 'radio' && jQuery(this).val() === '1') {
				jQuery("input[name*='_consent'][value='1']").prop('checked', true);
			}
            if (jQuery(this).is(':checked') === false) {
                jQuery("input[name*='_consent'][value='1']").prop('checked', false);
            }
		});
		jQuery("input[name*='_consent']").change(function(){
			if(jQuery(this).attr('name') !== 'jform[snprofile][general_consent]'){
				if(jQuery(this).attr('checked') !== 'checked' || jQuery(this).val() === '0'){
					if(jQuery('input[name="jform[snprofile][general_consent]"]').attr('type') === 'checkbox'){
						jQuery('input[name="jform[snprofile][general_consent]"]').prop('checked', false);
					} else {
						jQuery('input[name="jform[snprofile][general_consent]"]').last().prop('checked', true);
					}

				}
			}
		});
	}
	if(jQuery('#lp-order').length > 0){
		jQuery.data(jQuery('#lp-order')[0], 'validator').settings.ignore = "";
	}
	if(jQuery('fieldset.snprofile').is(':visible')){
		jQuery.data(jQuery('#member-registration')[0], 'validator').settings.ignore = "";
	}
	// pop-up behaviour
	if(jQuery('body').data('popup-active') === 1){
		if(jQuery('body').data('popup-delay') !== 0){
			setTimeout(function(){
				jQuery('#popUpModal').modal('show');
			}, jQuery('body').data('popup-delay'));
		} else {
			jQuery('#popUpModal').modal('show');
		}
	}
	if(jQuery('body').data('exit-popup') === 1){
		jQuery(document).mousemove(function(e){
			if(e.pageY > maxWindowHeight){
				maxWindowHeight = e.pageY;
			}
			if(e.pageY <= 5 && maxWindowHeight > 75){
				if(escapeActivated === false){
					jQuery('#exitPopUpModal').modal('show');
					escapeActivated = true;
				}
			}
		});
	}
    jQuery(document).on('click','#jform_email1-error a.btn-primary',function() {
        saveUserDetailsToSession();
    });

	jQuery('#checkoutBtn').click(function(e){
		e.preventDefault();
		e.stopPropagation();
        if (isLp && isInitialFormValid()) {
            jQuery("#jform_email1").rules("add", {
                userExists: true
            });
		}

		if(jQuery('#member-registration').valid()){
	        var formData = jQuery('fieldset.default').serialize();
            jQuery.ajax({
                type 	: 'POST',
                url 	: 'index.php?option=com_snv2&view=landingpage&task=landingpage.checkEmailConsent&format=json',
                data 	: formData,
                dataType: 'json'
            }).done(function(data){
                if(data.data === 1){
                    jQuery('.emailconsent').addClass('hidden').find('input').attr('checked', 'checked');
                }
            });
            jQuery('#checkoutBtn').css('display', 'none');
            jQuery('.errorbox').html('').slideUp();
            jQuery('#jform_ac').parents().eq(1).slideUp('fast');
            jQuery('fieldset.snprofile').delay(1000).slideToggle('slow');
            jQuery.data(jQuery('#member-registration')[0], 'validator').settings.ignore = "";
            jQuery('.notifyme__progress').html('');

			window.dataLayer = window.dataLayer || [];
			dataLayer.push({
				"ecommerce": {
					"currencyCode": gtmJs.currencyCode,
					"actionField": gtmJs.detail.actionField,
					"add": {
						"products": [{
							"id": gtmJs.detail.products[0].id,
							"name": gtmJs.detail.products[0].name,
							"price": gtmJs.detail.products[0].price,
							"category": gtmJs.detail.products[0].category,
							"quantity": 1
						}]
					}
				},
				"event": "addToCart"
			});
			dataLayer.push({
				"ecommerce": {
					"checkout": {
						"actionField": {'step' : 1},
						"products": [{
							"id": gtmJs.detail.products[0].id,
							"name": gtmJs.detail.products[0].name,
							"price": gtmJs.detail.products[0].price,
							"category": gtmJs.detail.products[0].category,
							"quantity": 1
						}]
					}
				},
				"event": "BillingAndDelivery"
			});
			dataLayer.push({
				"ecommerce": {
					"checkout": {
						"actionField": {'step' : 2},
						"products": [{
							"id": gtmJs.detail.products[0].id,
							"name": gtmJs.detail.products[0].name,
							"price": gtmJs.detail.products[0].price,
							"category": gtmJs.detail.products[0].category,
							"quantity": 1
						}]
					}
				},
				"event": "BillingAndDelivery"
			});
		} else {
			if (jQuery('.has-error').length) {
				setTimeout(() => { // wait for jQuery validate to add error classes
					TemplateHelper.scrollToInvalidField();
				}, 100);
			}
		}

		jQuery('#payment_online').change(function(){
			var $this = jQuery(this);
			if($this.attr('checked')){
				jQuery('.payment__online').slideDown();
		    }
		});
		jQuery('#payment_invoice').change(function(){
			var $this = jQuery(this);
			if ($this.attr('checked')) {
				jQuery('.payment__online').slideUp();
		    }
		});
	});

	jQuery(document).on('click','img.upsell__img, a.checkout',function() {
		const notSet = 'not set';
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			"ecommerce": {
				"currencyCode": gtmJs.currencyCode,
				"click": {
					"products": [{
						"id": gtmJs.id,
						"name": gtmJs.name,
						"price": gtmJs.price,
						"brand": gtmJs.brand,
						"material": gtmJs.material,
						"theme": gtmJs.theme,
						"list": 'One-pager Confirmation Upsell'
					}]
				}
			},
			"event": "ProductClick"
		});
		window.dataLayer.push({
			'event': 'promotionClick',
				'ecommerce': {
				'promoClick': {
					'promotions': [
						{
							'id': upsell_seo_ac || notSet,
							'name': upsell_seo_name || notSet,
							'creative': upsell_seo_creative || notSet,
							'position': 'thank-you-banner-1'
						}
					]
				}
			}
		});
	});

	if (jQuery('img.upsell__img').length && jQuery('img.upsell__img').isInViewport()) {
        const notSet = 'not set';
        dataLayer.push({
			'event': 'promoView',
			'ecommerce': {
				'promoView': {
					'promotions': [
						{
							'id': upsell_seo_ac || notSet,
							'name': upsell_seo_name || notSet,
							'creative': upsell_seo_creative || notSet,
							'position': 'thank-you-banner-1'
						}
					]
				}
			}
		});
	}

	jQuery('#payment_online').change(function(){
		var $this = jQuery(this);
		if ($this.is(':checked')) {
			jQuery('.payment__online').slideDown();
			slideSsn('Up');
	    }
	});

	jQuery('#payment_invoice').change(function(){
		const $this = jQuery(this);
		if ($this.is(':checked')) {
			jQuery('.payment_online').slideUp();
			slideSsn('Down');
	    }
	});
    jQuery('#payment_cod').change(function(){
        var $this = jQuery(this);
        if ($this.is(':checked')) {
            jQuery('.payment__online').slideUp();
            slideSsn('Up');
        }
    });
    slideSsn();


	if(jQuery('label[for="payment_installments"]').parents("div[class='control-group']")) {
		var installment2 = jQuery('input#payment_installments[value="installments"]');
		if(installment2.attr('checked') === 'checked') {
			jQuery('#installments_table :hidden').show('400');
		} else {
			jQuery('#installments_table :visible').hide('400');
		}
	}

	if(jQuery("input[name='jform[upgrade]']").length){
		jQuery("input[name='jform[upgrade]']").change(function(){
			var $this = jQuery(this);
			var $productToBuy = jQuery('input[name="jform[id]"]');
			var upgradeId = parseInt(jQuery('input[name="upgrade_pid"]').val());
			var originId = parseInt(jQuery('input[name="origin_pid"]').val());
			if(parseInt($this.val()) === 1){
				$productToBuy.val(upgradeId);
			} else {
				$productToBuy.val(originId);
			}
		});
	}

	if(jQuery('#shipment_pickup_point').prop('checked')) {
		jQuery('.control-group--pickuppoints').slideDown(200);
	}

    jQuery('#payment__button').click(function(e){
        e.preventDefault();
        e.stopPropagation();

        jQuery("#shipping-payments").validate({
            ignore: '',
            rules: {
                parcel_machines: {required: '#shipment_pickup_point:checked'},
                terminal_list: {required: '#shipment_pickup_point:checked'},
                omniva_select1: {required: '#shipment_pickup_point:checked'}
            },
            messages: {
                parcel_machines: jQuery('form#shipping-payments').data('required'),
                terminal_list: jQuery('form#shipping-payments').data('required'),
                omniva_select1: jQuery('form#shipping-payments').data('required')
            },
            errorClass: 'has-error',
            errorPlacement: function (error, element) {
                error.insertAfter(element);
            },
            highlight: function (element, errorClass, validClass) {
                element = jQuery(element);
                element.addClass(errorClass).removeClass(validClass);
            },
            unhighlight: function (element, errorClass, validClass) {
                element = jQuery(element);
                element.removeClass(errorClass).addClass(validClass);
            }
        });
        setTimeout(function() { jQuery('#shipping-payments').submit(); }, 50);
    });

	jQuery('input[name$="shipment"], input[name$="payment"]').change(function(e){
        worldPay.showRecurringPayment('landingpage');
		e.preventDefault();
		e.stopPropagation();
		var $form;
		if(jQuery('#shipping-payments').length > 0) {
            $form = jQuery('#shipping-payments');
        }else{
            $form = jQuery('#lp-order');
		}
        if(jQuery('#payment_online').is(':checked') || jQuery('#payment_payu_installments').attr('checked')){
            jQuery('#payment__button').text(jQuery('#payment__button').data('payment-text')).removeClass('disabled');
			jQuery('#checkout').text(jQuery('#payment__button').data('payment-text'));
		} else {
			jQuery('#payment__button').text(jQuery('#payment__button').data('regular-text')).removeClass('disabled');
			jQuery('#checkout').text(jQuery('#payment__button').data('regular-text'));
		}

		if(jQuery('html').attr('lang') === 'et-ee'){
			if(jQuery('#shipment_pickup_point').prop('checked')){
				jQuery('.control-group--pickuppoints').slideDown(200).find('select#omniva_select1').prop('required',true);
			} else {
				jQuery('.control-group--pickuppoints').slideUp(200).find('select#omniva_select1').prop('required',false);
			}
            jQuery('select#omniva_select1').change(function(e){
                e.preventDefault();
                jQuery('.basket__summary').attr("tabindex",-1).focus();
                jQuery('.firstpayment').removeClass('disabled')
            });
		}
        if(['lt-lt', 'lv-lv'].includes(jQuery('html').prop('lang')) === true){
            let shippingType = jQuery('input[name=shipment]:checked').val(),
                pickup_point = jQuery('.control-group--parcel_machines'),
                shipment_pickup_point = jQuery('.control-group--pickuppoints')

            if(shippingType === 'pickup_point'){
                pickup_point.slideDown(200).find('select.pickuppoint').prop('required',true)
            } else {
                pickup_point.slideUp(200).find('select.pickuppoint').prop('required',false)
            }

            if(jQuery('#shipment_pickup_point').prop('checked')){
                shipment_pickup_point.slideDown(200).find('select.pickuppoint').prop('required',true);
            } else {
                shipment_pickup_point.slideUp(200).find('select.pickuppoint').prop('required',false)
            }
            if(shippingType === 'pickup_point'){
                let fields = {
                    'task' : 'checkout.getParcelPoints'
                };
                const response = sendAjaxRequest('checkout', 'getParcelPoints', fields);
                response.then(data => {
                    if(data.success === true && !!data.data){
                        balticParcelPoints(data.data)
                        jQuery('select.pickuppoint--lp_express').change(function(e){
                            e.preventDefault();
                            jQuery('select.pickuppoint--lp_express').not(this).prop('selectedIndex',0);
                            let id = jQuery(this).attr('id');
                            jQuery('#pickuppoint').val(jQuery('select#'+id+' option:selected').val());
                            jQuery('#pickuppoint_text').val(jQuery('select#'+id+' option:selected').text());
                            jQuery('.basket__summary').attr("tabindex",-1).focus();
                            jQuery('.firstpayment').removeClass('disabled')
                        });
                    } else {
                        let error = jQuery('.control-group--pickuppoints').data('error');
                        jQuery('select.pickuppoint--lp_express').hide().parents(':eq(1)').html('<div class="controls--invalid">'+error+'</div>');
                    }
                }).catch(error => {
                    console.error('checkout ~ getParcelPoints', error);
                });
            }
        }

		if( ['fi-fi','sv-se'].includes(jQuery('html').attr('lang')) ){
			jQuery('.basket__total').html('<i class="fa fa-circle-o-notch fa-spin"></i>');
		}

		jQuery.ajax({
			type 	: 'POST',
			url 	: 'index.php?option=com_snv2&task=landingpage.recalculate&format=json',
			data 	: $form.find('input[type=radio]').serialize() + '&' + $form.find('input[name=pId]').serialize()
		}).done(function(data){
			if(data.success){
				if(jQuery('html').attr('lang') === 'fi-fi'){
					jQuery('#basketSummary').html('<i class="fa fa-circle-o-notch fa-spin"></i>');
					var shippingInput = jQuery('input[id^=shipment_]:checked');
					var shipping = shippingInput.attr('value');
					var dataToSend = {
						'orderId' : jQuery('input[name=orderId]').attr('value'),
						'shipping' : shipping,
						'pId' : jQuery('input[name=pId]').attr('value'),
					};
					jQuery.ajax({
						type 	: 'POST',
						url 	: 'index.php?option=com_snv2&task=landingpage.recalculateVat&format=json',
						data 	:  {'json':dataToSend}
					}).done(function(data2){
						if(data2.success){
							renderVat(data2.data);
						} else {
							jQuery('.basket__total').html('Please refresh site to recalculate cart.');
						}
					});
				}
				setTimeout(function() { jQuery('#basketSummary').html(data.data); }, 500);
			} else {
				jQuery('#basketSummary').html('Please refresh site to recalculate cart.');
			}
		});

	});

    if(lang === 'uk-uk') {
        let buildingInput = jQuery('#jform_snprofile_building');
        buildingInput.keyup(function() {
            let val = buildingInput.val();
            let warningLabel = jQuery('#jform_snprofile_building-warning');
            if (parseInt(val) > 1900 && warningLabel.length === 0) {
                buildingInput.parent().append('<label id="jform_snprofile_building-warning" class="has-warning" for="jform_snprofile_building">'+Joomla.JText._('COM_SNV2_BUILDING_WARNING_LABEL')+'</label>');
                return;
            } else if(parseInt(val) <= 1900) {
                warningLabel.remove();
            }
        });
    }

	jQuery('#orderAndShippingConfirm').click(function(e){
		e.preventDefault();
		e.stopPropagation();
		jQuery(this).slideUp().parent().hide();
		jQuery('#lp-order').find('fieldset').slideDown();
        worldPay.showRecurringPayment('landingpage');
	});

	 // send magic link
	jQuery(document).on('click', '#sendMagicLink', function(e) {
		e.preventDefault();
		e.stopPropagation();
		const token = jQuery('#member-registration input[type=hidden][value="1"]').last().attr('name');
		let dataToSend = {
			'format': 'json',
			'returnUrl' : encodeURI(window.location.href),
			'email' : jQuery('#jform_email1').val()
		};
		dataToSend[token] = '1';
        const email = jQuery('#jform_email1').val();
        if(email === '') {
            jQuery('#emptyEmailField').css('display', 'block');
            jQuery('#successMessage').css('display', 'none');
            jQuery('#magicLinkSuccess').modal('show');
            return;
        }
		jQuery.ajax({
			type 	: 'POST',
			url 	: 'index.php?option=com_snv2&task=sendMagicLink',
			data 	: dataToSend
		}).done(function(data){
			if(data.success && data.data === true){
				jQuery('#successMessage').css('display', 'block');
				jQuery('#failsafeMessage').css('display', 'none');
				jQuery('#jform_email1-error').hide(300);
				jQuery('#magicLinkSuccess').modal('show');
			} else {
				jQuery('#successMessage').css('display', 'none');
				jQuery('#failsafeMessage').css('display', 'block');
				jQuery('#magicLinkSuccess').modal('show');
			}
		});
	});
	// prevent click on label for email field to avoid secondary validation
	jQuery(document).on('click', '#jform_email1-error', function(e) {
		e.preventDefault();
		e.stopPropagation();
	});

	jQuery('#confirm_button').click(function (e) {
		e.preventDefault();
		jQuery('input[name=upgrade_alternative_buy]').val(1);
		jQuery('#upgrade_alternative').modal('hide');
	});
	jQuery('#cancel_button').click(function (e) {
		e.preventDefault();
		jQuery('input[name=upgrade_alternative_buy]').val(0);
		jQuery('#upgrade_alternative').modal('hide');
	});

	jQuery('#offerForm').find('div.js-offer-button').click(function(element) {
		jQuery('#offerForm').hide();
		jQuery('#offer').val(jQuery(this).attr('value'));
		jQuery('#product');
		var fields = {
			id : jQuery('input[name="id"]').val(),
			orderId : jQuery('input[name="orderId"]').val(),
			productId : jQuery('input[name="offerProductId"]').val(),
		};
		if(parseInt(jQuery(this).attr('value'))) {
			sendAjaxRequest('landingpage','purchaseOrder', fields);
		}

	});

	if(jQuery('#dibseasy__wrapper').length) {
		window.dibsConfig = JSON.parse(document.getElementById('dibseasy__wrapper').getAttribute('data-dibsconfig'));
		if(dibsConfig.paymentId !== null){
			window.checkout = new Dibs.Checkout(window.dibsConfig);
			jQuery('#shipping-payments').slideUp(500);
			jQuery('#lp-order').slideUp(500);
			dibsEasyBindPaymentCompleted();
		}
	}

	initTogglePasswordVisibility('#modlgn-passwd', 23, 4);

	// Set form param on the mobile bottom bar button
	// Using click trigger instead of form="form_name", because of 'disabled button' logic.
	let buttonId = '#oneClickOrder';
	if (jQuery('#oneClickOrder').length) {
		buttonId = '#oneClickOrder';
	} else {
		buttonId = '.addToCartBtn';
	}
	jQuery('.bottomMenu__buy').on('click', () => {
		jQuery(buttonId).trigger('click');
	});
	TemplateHelper.setBottomButtonPadding();
    worldPay.showRecurringPayment('landingpage');
});

jQuery(window).keydown(function(event){
	if(event.keyCode === 13) {
		event.preventDefault();
		if(jQuery('#checkoutBtn').length > 0 && jQuery('#checkoutBtn').css('display') !== 'none'){
			jQuery('#checkoutBtn').trigger('click');
		}
		return false;
	}
});

jQuery('label[for="payment_installments"]').parents("div[class='control-group']").click(function(){
	var installment = jQuery('input#payment_installments[value="installments"]');
	if(installment.attr('checked') === 'checked') {
		jQuery('#installments_table :hidden').show('400');
	} else {
		jQuery('#installments_table :visible').hide('400');
	}
});

function isInitialFormValid(){
    var valid = true;

    jQuery('#member-registration .default .control-group:visible').each(function(){
    	if(jQuery(this).find('input').is('#jform_snprofile_first_name') && jQuery(this).find('input').val() === ''){
    		valid = false;
		}
        if(jQuery(this).find('input').is('#jform_snprofile_last_name') && jQuery(this).find('input').val() === ''){
            valid = false;
        }
    });

    return valid;
}

function renderVat(v) {
	var output = '';
	var collection = '';

	output += '<tr class="vat basket__summary">';
	output += '<td class="col-md-12" style="padding-bottom: 0px;"  colspan="4">';
	Object.keys(v).forEach(function(key) {
		gross = v[key].gross;
		nett = v[key].net;
		vat = v[key].vat;
		if(v[key].name==='all'){
			vatAll = vat;
			output += '<ul class="basket_list basket_list--vat">\r\n';
			output += '<li class="col-md-8" id="vat_all_desc">'+Joomla.JText._('COM_SNV2_VAT_NET')+'</li>';
			output += '<li class="col-md-4 basket__total text-right">'+nett+'</li>';
			output += '</ul>\r\n';
		} else if(v[key].name==='0') {
			output += '<ul class="basket_list basket_list--vat">\r\n';
			output += '<li class="col-md-8" id="vat_all_desc">'+Joomla.JText._('COM_SNV2_VAT_0')+'</li>';
			output += '<li class="col-md-4 basket__total text-right">'+vat+'</li>';
			output += '</ul>\r\n';
		} else if(v[key].name==='shipping') {
			output += '<ul class="basket_list basket_list--vat">\r\n';
			output += '<li class="col-md-8" id="vat_all_desc">'+Joomla.JText._('COM_SNV2_VAT_SHIPPING')+'</li>';
			output += '<li class="col-md-4 basket__total text-right">'+vat+'</li>';
			output += '</ul>\r\n';
		} else if(v[key].name!=='0') {
			output += '<ul class="basket_list basket_list--vat">\r\n';
			output += '<li class="col-md-8" id="vat_all_desc">'+Joomla.JText._('COM_SNV2_VAT_'+v[key].name.toUpperCase())+'</li>';
			output += '<li class="col-md-4 basket__total text-right">'+vat+'</li>';
			output += '</ul>\r\n';
		}
	});
	output += '<ul class="basket_list basket_list--vat">\r\n';
	output += '<li class="col-md-8" id="vat_all_desc">'+Joomla.JText._('COM_SNV2_VAT_TOTAL_VAT')+'</li>';
	output += '<li class="col-md-4 basket__total text-right">'+vatAll+'</li>';
	output += '</ul>\r\n';


	output += '</td>';
	output += '</tr>';

	jQuery('tr.vat').remove();
	jQuery('tbody').prepend(output);
	return output;
}

function slideSsn(slide) {
	if(jQuery('#jform_snprofile_ssn_1').length) {
		if(jQuery('#jform_snprofile_ssn_1').data('value') !== '') {
			jQuery('#ssn-slide-js').closest('.control-group').slideUp();
			return;
		} else {
			if(slide==='Up') {
				jQuery('#ssn-slide-js').closest('.control-group').slideUp();
			} else if (slide==='Down') {
				jQuery('#ssn-slide-js').closest('.control-group').slideDown();
			}
		}
	}
}

async function fetchAndDisplayMonthlyPayment(selectedOption, totalPrice, deposit, loanValue) {
    const id = selectedOption.Id;

    try {
        const response = await sendAjaxRequest('landingpage', 'getFinanceCalculatorProductData', {
            price: totalPrice,
            deposit: deposit,
            RetailerRateCardProducts: id
        });

        if (response && response.data.MonthlyPayment !== undefined) {
            jQuery(`#monthly-payment-${id}`).html(`£${response.data.MonthlyPayment.toFixed(2)}`);
            jQuery(`#deposit-${id}`).html(`£${response.data.Deposit.toFixed(2)}`);
            jQuery(`#loan-value-${id}`).html(`£${response.data.LoanValue.toFixed(2)}`);
            jQuery(`#total-price-${id}`).html(`£${response.data.Price.toFixed(2)}`);
            jQuery(`#total-interest-payable-${id}`).html(`£${response.data.TotalInterestPayable.toFixed(2)}`);
            jQuery(`#apr-${id}`).html(`${response.data.APR.toFixed(2)}%`);
            jQuery('.payment_omni_finance_options #financePlanSelectPayment').css('display', 'block');
        } else {
            console.error('Error: No MonthlyPayment found in response');
        }
    } catch (error) {
        console.error('Error fetching finance calculator data:', error);
    }
}

async function getOmniOptions() {
    try {
        const response = await sendAjaxRequest('landingpage', 'getOmniFinanceInstallments', {});
        if (response.success && response.data) {
            let selectOptions = '<select id="financePlanSelect" name="omni-finance-installment-plan-modal" class="form-control">';
            response.data.forEach(function(option) {
                selectOptions += `<option value="${option.ProductCode}">${option.FullName}</option>`;
            });
            selectOptions += '</select>';

            jQuery('.omni-modal-options').html(selectOptions);
            let paymentSelectOptions = selectOptions
                .replace('financePlanSelect', 'financePlanSelectPayment')
                .replace('omni-finance-installment-plan-modal', 'omni-finance-installment-code');

            jQuery('.payment_omni_finance_options').html(paymentSelectOptions);
            jQuery('.omni-modal-options').append('<div id="financePlanDetails" class="well"></div>');

            const syncSelects = () => {
                const modalSelectValue = jQuery('.omni-modal-options #financePlanSelect').val();
                jQuery('.payment_omni_finance_options #financePlanSelectPayment').val(modalSelectValue);

            };
            jQuery('.payment_omni_finance_options #financePlanSelectPayment').css('display', 'none');
            console.log('getOmniOptions');


            jQuery('.omni-modal-options #financePlanSelect').on('change', function () {
                const selectedOption = response.data.find(option => option.ProductCode === this.value);
                syncSelects();

                if (selectedOption) {
                    const totalPrice = parseFloat(jQuery('#total_lp').val());
                    const deposit = parseFloat(totalPrice * 0.1).toFixed(2);
                    const loanValue = parseFloat(totalPrice - deposit).toFixed(2);
                    const annualInterestRate = parseFloat(selectedOption.AnnualInterestRate).toFixed(2);
                    const totalInterestPayable = parseFloat(loanValue * (annualInterestRate / 100)).toFixed(2);

                    let details = `
                        <ul class="list-group">
                            <li class="list-group-item"><strong>Loan Type:</strong> ${selectedOption.FullName}</li>
                            <li class="list-group-item"><strong>Price:</strong> <span id="total-price-${selectedOption.Id}"> - </span></li>
                            <li class="list-group-item"><strong>Deposit:</strong> <span id="deposit-${selectedOption.Id}"> - </span></li>
                            <li class="list-group-item"><strong>Loan Value:</strong> <span id="loan-value-${selectedOption.Id}"> - </span></li>
                            <li class="list-group-item"><strong>Total Term:</strong> ${selectedOption.TotalTerm} months</li>
                            <li class="list-group-item"><strong>APR (Annual Interest Rate):</strong> <span id="apr-${selectedOption.Id}"> - </span></li>
                            <li class="list-group-item"><strong>Total Interest Payable:</strong> <span id="total-interest-payable-${selectedOption.Id}"> - </span></li>
                            <li class="list-group-item"><strong>Monthly Payment:</strong>
                                <div id="monthly-payment-${selectedOption.Id}" style="display:inline"> - </div>
                            </li>
                        </ul>
                    `;
                    jQuery('#financePlanDetails').html(details);

                    const paymentElement = jQuery(`#monthly-payment-${selectedOption.Id}`);
                    if (paymentElement.text().trim() === '-') {
                        fetchAndDisplayMonthlyPayment(selectedOption, totalPrice, deposit, loanValue);
                    }
                }
            });

            jQuery('.payment_omni_finance_options #financePlanSelectPayment').on('change', function () {
                const selectedOption = jQuery(this).val();
                jQuery('.omni-modal-options #financePlanSelect').val(selectedOption).trigger('change');
            });

            jQuery('.omni-modal-options #financePlanSelect').trigger('change');
        }
    } catch (error) {
        console.error('Error getOmniOptions:', error);
        jQuery('.addToCartBtn').prop('disabled', false);
    }
}

async function getOmniFinanceInstallments() {
    const htmlLang = document.documentElement.lang;
    if (htmlLang !== 'uk-uk') {
        return;
    }
    if (typeof sendAjaxRequest !== 'function') {
        console.error('sendAjaxRequest is not a function ...');
        return;
    }
    const paymentOmniFinance = document.getElementById('payment_omni_finance');
    if (!paymentOmniFinance) {
        return;
    }

    async function handleOmniFinance() {
        jQuery('.addToCartBtn').prop('disabled', true);
        await getOmniOptions();
        jQuery('#omniFinanceModal').modal('show');
        jQuery('.payment_omni_finance_options #financePlanSelectPayment').css('display', 'block');
        jQuery('.addToCartBtn').prop('disabled', false);
    }

    if (paymentOmniFinance.checked) {
        await handleOmniFinance();
    }

    paymentOmniFinance.addEventListener('change', async () => {
        if (paymentOmniFinance.checked) {
            await handleOmniFinance();
        }
    });
}




jQuery.validator.addMethod("userExists", function(value, element) {
    // ajax call to check if email exists in DB
    let fieldValid = false;
    jQuery.ajax({
        type: "POST",
        async: false,
        url: "index.php?option=com_snv2&task=getUserByEmail&tmpl=component&format=json",
        dataType: 'json',
        data: {'email': value},
        success:function(data){
            if(data.success === true && data.data !== ''){
                if(data.data === 1) {
                    jQuery('#password-remind, #password-remind-form').hide()
                }
                fieldValid = (data.data === 0);
            }
        }
    }).fail(function(){
        fieldValid = true;
    });

    return this.optional(element) || fieldValid;

}, Joomla.JText._('COM_SNV2_LP_USER_EXISTS_MESSAGE'));

// Setup a listener to track Add to Homescreen events.
window.addEventListener('beforeinstallprompt', function (e) {
  e.userChoice.then(function (choiceResult) {
    ga('send', 'event', 'A2H', choiceResult.outcome);
  });
});



const eventTracking = function () {
	console.log('event trackign');
    jQuery('[data-event="ev"]').click(function () {
        var evCat = jQuery(this).data('category') ? jQuery(this).data('category') : '',
            evAct = jQuery(this).data('action') ? jQuery(this).data('action') : '',
            evLab = jQuery(this).data('label') ? jQuery(this).data('label') : '',
            evVal = jQuery(this).data('value') ? jQuery(this).data('value') : '';
        try {
            dataLayer.push({
                'event': 'trackEvent',
                'eventCategory': evCat,
                'eventAction': evAct,
                'eventLabel': evLab,
                'eventValue': evVal,
            });
            console.log({
                'event': 'trackEvent',
                'eventCategory': evCat,
                'eventAction': evAct,
                'eventLabel': evLab,
                'eventValue': evVal,
            });
        } catch (e) {
            console.log(e);
        }
    });
    jQuery('.upsellarea a').click(function () {
        try {
            dataLayer.push({
                'event': 'trackEvent',
                'eventCategory': 'gl-thankYouPage',
                'eventAction': 'upsellClick',
                'eventLabel': '',
                'eventValue': '',
            });
        } catch (e) {
            console.log(e);
        }
    });
};

jQuery(window).on('load', function() {
	eventTracking();
});

jQuery.fn.isInViewport = function () {
	var elementTop = jQuery(this).offset().top;
	var elementBottom = elementTop + jQuery(this).outerHeight() - 5;
	var viewportTop = jQuery(window).scrollTop();
	var viewportBottom = viewportTop + jQuery(window).height();
	return elementBottom < viewportBottom;
}
