import sendAjaxRequest from './../../../../../../templates/sn_webshop/dev/js/helpers/sendAjaxRequest.js';

class worldPay {

    constructor(context, formId = '')
    {
        this.context = context;
        this.formToHideSelector = '';
        switch (context) {
            case 'checkout':
            case 'payinvoice':
                this.formToHideSelector = '#shipping-payments';
                break;
            case 'landingpage':
                this.formToHideSelector = '#lp-order';
                if(formId!=='') {
                    this.formToHideSelector = '#'+formId;
                }
                break;
            case 'vista':
                this.formToHideSelector = '.form--checkout';
                break;
            case 'createorder':
                this.formToHideSelector = '#adminForm';
                break;
            default:
                this.formToHideSelector = '.form--checkout';
                break;
        }

    }

    canProcessPayment()
    {
        switch (this.context) {
            case 'checkout':
            case 'payinvoice':
                if(jQuery('.js-worldPay').length) {
                    return jQuery('#payment_online').prop('checked') || jQuery('#payment_paypal').prop('checked');
                }
                return false;
            case 'landingpage':
                if(jQuery('#payment_online').length) {
                    return jQuery('.js-worldPay').length && (jQuery('#payment_online').prop('checked') || jQuery('#payment_paypal').prop('checked'));
                }
                return false;
            case 'vista':
                return jQuery('.js-worldPay').length && (jQuery('#payment_online').prop('checked') || jQuery('#payment_paypal').prop('checked'));
            default:
                return jQuery('.js-worldPay').length > 0;
        }
    }

    static showRecurringPayment(context)
    {
        switch (context) {
            case 'checkout':
            case 'vista':
                if(jQuery('#payment_online').prop('checked')) {
                    jQuery('.basket__recurring_payment').show(500);
                    return;
                }
                jQuery('.basket__recurring_payment').hide(500);
                return;
            case 'landingpage':
                if(jQuery('#payment_online').length && jQuery('#payment_online').prop('checked')) {
                    jQuery('.basket__recurring_payment').show(500);
                    return;
                }
                jQuery('.basket__recurring_payment').hide(500);
                return;
            default:
                jQuery('#adminForm').hide(500);
                break;
        }
    }

    parseWorldPayResponse(data)
    {
        try {
            let object = JSON.parse(data);
            if(object.errorCode && object.errorValue) {
                var messages = {
                    "error": [object.errorValue]
                };
                window.Joomla.renderMessages(messages);
                return;
            }
        } catch (error) {}
        var messages = {
            "error": [data]
        };
        window.Joomla.renderMessages(messages);
        return;
    }

    showWorldPayIframe(data, containerId) {
        if(jQuery('#'+containerId).length){
            jQuery('#'+containerId).html(data);
            jQuery('.checkout').css('display','none');
        }
        jQuery(this.formToHideSelector).hide(500);
    }

    sendRequest(controller, method, formId) {
        const that = this;
        let data = jQuery('#'+formId).find('input[name!=task], select[name^="jform"]').serialize();
        let response = sendAjaxRequest(controller, method, data, true);
        response.then(
            function(result) {
                if(result.success) {
                    that.showWorldPayIframe(result.data, 'js-worldPay');
                    return;
                } else {
                    if(result.data) {
                        that.parseWorldPayResponse(result.data);
                        return;
                    }
                    var messages = {
                        "error": [Joomla.JText._('COM_SNV2_PAYMENT_WORLD_PAY_ERROR')]
                    };
                    window.Joomla.renderMessages(messages);
                    return;

                }


            },
            function(error) {
                /* handle an error */
                var messages = {
                    "error": [Joomla.JText._('COM_SNV2_PAYMENT_WORLD_PAY_ERROR')]
                };
                window.Joomla.renderMessages(messages);
                return;


            }).catch(error => {
                console.error('checkout ~ sendAjaxRequest', error);
        });
    }

}


export default worldPay;
