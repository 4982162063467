export default function balticParcelPoints(parcelPoints) {
    for (const pointType in parcelPoints) {
        // ---------- TERMINAL_LIST points LT ----------
        if (pointType === 'terminal_list') {
            let terminal_list = '<option value="">---</option>';
            parcelPoints[pointType].forEach((item) => {
                terminal_list += '<option value="' + item.id + '">' + item.name + '</option>';
            });
            jQuery('#terminal_list').html(terminal_list).chosen();
        }
        // ---------- PARCEL_MACHINES points LV ----------
        if (pointType === 'parcel_machines') {
            let parcel_machines = '<option value="">---</option>';
            const items = parcelPoints[pointType]
            for (const pointType in items) {
                parcel_machines += '<optgroup label="'+pointType+'">'
                items[pointType].forEach((item) => {
                    parcel_machines += '<option value="' + item.id + '">' + item.name + '</option>';
                });
                parcel_machines += '</optgroup>'
            }
            jQuery('#parcel_machines').html(parcel_machines).chosen();
        }
    }
}
