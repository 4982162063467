import sendAjaxRequest from './sendAjaxRequest'

export default class TemplateHelper {
    static setBottomButtonPadding() {
        setTimeout(() => {
            if (jQuery('.stickyMobileBottomBar:visible').length) {
                const buttonHeight = jQuery('.stickyMobileBottomBar:visible').outerHeight()
                jQuery('body').attr('style', `padding-bottom: ${buttonHeight}px!important;`)
            }
        }, 100)
    }

    static scrollToInvalidField() {
        jQuery('html, body').animate({
            scrollTop: jQuery('input.has-error').first().parent().offset().top - 100
        }, 300)
    }

    static initPasswordRemind(
        buttonSelector = '#password-remind',
        formSelector = '#remindme-form'
    ) {
        if (jQuery(buttonSelector).length) {
            jQuery(buttonSelector).on('click', (e) => {
                e.preventDefault()
                e.stopPropagation()
                jQuery(formSelector).slideToggle()
            })
        }
    }

    static initFullWidthMenu() {
        // When the menu has multiple rows and 'full-width' is on, add an element after the last menu item, that will align the last row to the left.
        if (jQuery('.navigation.full-width-menu')) {
            const $menuLayout = jQuery('.navigation.full-width-menu .menu__layout')
            const $menuItems = $menuLayout.find('> .menu__item')
            if ($menuLayout && $menuItems) {
                if (
                    $menuLayout.outerHeight() < $menuItems.first().outerHeight()
                ) {
                    $menuItems.last().after('<li style="flex:auto;"></li>')
                }
            }
        }
    }

    static initPopupBasket(selector) {
        jQuery(selector).on('click', function(e) {
            e.preventDefault()
            jQuery('.modal-header h5').html(Joomla.JText._('COM_SNV2_RETURNS_CHECKING'))
            const isMostPopular = jQuery('body').hasClass('isFrontpage') && jQuery(this).hasClass('xcolumns_addtocart')
            let form
            if (isMostPopular) {
                jQuery('input[name=\'pId\']').val(jQuery(this).attr('id'))
                form = jQuery('form#xcolumns_cart')
            } else {
                form = jQuery(this).closest('form')
            }
            const post_url = form.attr('action')
            const form_data = form.serialize()

            jQuery('.productPlace').html('<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>')

            let fields = {
                'form' : jQuery('#cart__form').serializeArray(),
                'pid': jQuery(this).data('pid'),
                'type': 'JSON'
            }
            let promise = sendAjaxRequest('basketfront', 'addToCart', fields, false)
            promise.then(function(msg) {
                let data = msg.data.data,
                    addedProduct = ''
                if(data.status === true) {
                    addedProduct += `<div class="row">`
                    addedProduct += `<div class="col-md-3 col-sm-4 col-xs-5 minicart__image"><img src="${msg.data.data.additionalData.img}" alt="${msg.data.data.additionalData.title}" title="${msg.data.data.additionalData.title}" /></div>`
                    addedProduct += `<div class="col-md-9 col-sm-8 col-xs-7 minicart__title"><div class="product-name">${msg.data.data.additionalData.title}</div><div class="minicart__price">${msg.data.data.additionalData.price}</div></div>`
                    addedProduct += `</div>`
                    jQuery('.modal-header h5').html(jQuery('.modal-header h5').data('success'))
                    jQuery('.productPlace').html(addedProduct)
                } else {
                    addedProduct += `<div class="row">`
                    addedProduct += `<div class="col-md-12 col-sm-8 col-xs-7 minicart__title">${data.msg}</div>`
                    addedProduct += `</div>`
                    jQuery('.modal-header h5').html(jQuery('.modal-header h5').data('error'))
                    jQuery('.productPlace').html(addedProduct)
                }

                jQuery.getJSON('index.php?option=com_snv2&task=getCartDetails&format=json', function(data) {
                    const basket_url = jQuery('a.fright').attr('data-url')
                    const cartItems = data.data.items
                    let cartPreview = '',
                        cartRows = '',
                        itemsSum = 0
                    for (const val of cartItems) {
                        itemsSum += parseInt(val.amount)
                        cartRows += `<div class="row">`
                        cartRows += `<div class="col-md-3 minicart__image"><img src="${val.image}" alt="${val.name}" title="${val.productAlias}" /></div>`
                        cartRows += `<div class="col-md-9 minicart__title">`
                        cartRows += val.amount > 1 ? val.amount + ' x ' : ''
                        cartRows += `<a class="inverted" href="${val.url}">${val.usp}</a> <span class="minicart__price">${val.price}</span></div>`
                        cartRows += `</div>`
                    }
                    cartRows += `<div class="row">`
                    cartRows += `<div class="col-md-8">${Joomla.JText._('COM_SNV2_CHECKOUT_CART_SHIPPING_LABEL')}</div>`
                    cartRows += `<div class="col-md-4 align-right">${data.data.shipment}</div>`
                    cartRows += `</div>`
                    cartRows += `<div class="row">`
                    cartRows += `<div class="col-md-8">${Joomla.JText._('COM_SNV2_CHECKOUT_CART_SUMMARY_LABEL')}</div>`
                    cartRows += `<div class="col-md-4 align-right"><b>${data.data.basketTotal}</b></div>`
                    cartRows += `</div>`
                    if (cartRows !== '') {
                        cartPreview += `<h5>${Joomla.JText._('TPL_SN_WEBSHOP_MINICART_HEADER')}</h5>`
                        cartPreview += cartRows
                        cartPreview += `<a class="promoproduct__cart ga-action" data-ga_category="MainPage" data-ga_action="basketclicked" href="${basket_url}">${Joomla.JText._('TPL_SN_WEBSHOP_MINICART_BUTTON')}</a>`
                    } else {
                        cartPreview = `<small>${Joomla.JText._('TPL_SN_WEBSHOP_MINICART_EMPTY')}</small>`
                    }
                    jQuery('.minicart .header__cart-mini').html(cartPreview)
                    jQuery('.cart__counter').text(itemsSum)
                })
            }).catch(err => {
                console.log(err)
            })
        })
    }

    static initTopBar() {
        if (jQuery('#topbar').length > 0) {
            if (jQuery('.top-banner').length) {
                jQuery('.top-banner').clone().appendTo('#topBanner')
                const topBannerHeight = jQuery('.top-banner').outerHeight()
                jQuery('.sitewrapper').css('padding-top', `${topBannerHeight}px`)
                jQuery('.topbar')
                    .css('padding-top', `${topBannerHeight}px`)
                    .css('top', `-=${topBannerHeight}`)
                jQuery('.navigation--mobile').css('top', `+=${topBannerHeight}`)
            }
            jQuery('.infobar__inner').clone().appendTo('#topBarInfo')
            jQuery('.menu__layout').clone().appendTo('#topBarMenu')
            jQuery('.header__cart').clone().appendTo('#topBarMenu .menu__layout').addClass('header__cart-second')
            jQuery('.header__cart-mini').clone().appendTo('#topBarMenu .menu__layout').addClass('header__cart-mini-second')
            jQuery('a.header__cart-second, div.header__cart-mini-second').wrapAll('<div class="minicart header__item" />')
        }
    }
}
