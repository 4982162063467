export default function sendAjaxRequest(task, method, fields, rawData = false, params = {}) {
    const token = jQuery('form input:hidden').last().serialize();
    let data = `${token}&${fields}`;
    let controllerPath = '';
    if(!rawData) {
        data = `${token}&fields=${JSON.stringify(fields)}`;
    }
    if(params) {
        if(params.hasOwnProperty('controllerPath')) {
            controllerPath = params.controllerPath;
        }
    }
    return new Promise((resolve, reject) => {
        jQuery.ajax({
            type: 'POST',
            url: `${controllerPath}index.php?option=com_snv2&task=${task}.${method}&${data}&tmpl=component&format=json`,
            data: data
        }).done(response => {
            if (response === 'The most recent request was denied because it had an invalid security token. Please refresh the page and try again.') {
                throw `An AJAX request error has ocurred! Invalid security token ((${method})). Please relog try again or contact to IT`;
            }
            resolve(response);
        }).fail(error => {
            reject(error);
        });
    });
}
