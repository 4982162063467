export function postNL(postal_code, housenumber) {
    if(postal_code !== '' && housenumber !== ''){
        jQuery('#address-empty, #address-notfound').addClass('hide');
        jQuery('#jform_snprofile_address, #jform_snprofile_postal').val('');
        jQuery.ajax({
            type: "POST",
            async: false,
            url: "index.php?option=com_snv2&task=vista.validAddressPostNL&tmpl=component&format=json",
            dataType: 'json',
            data: {
                'postalcode': postal_code,
                'housenumber': housenumber
            },
            success:function(data){
                if(data.success === true && data.data.city !== undefined && data.data.formattedAddress !== undefined){
                    jQuery('#jform_snprofile_postal_code, #jform_snprofile_housenumber').css('border-bottom', 'solid 2px #61D462');
                    jQuery('#jform_snprofile_address-lbl').parents(':eq(1)').hide();
                    jQuery('#jform_snprofile_postal-lbl').parents(':eq(1)').hide();
                    jQuery('#jform_snprofile_address').val(data.data.formattedAddress[0]);
                    jQuery('#jform_snprofile_postal').val(data.data.city);
                    let address = '<b>Adres</b> <span class="edit-address">Wijzig</span><br>' + data.data.formattedAddress[0] + '<br>' + postal_code.toUpperCase() + ' ' + data.data.city
                    jQuery('.address-success').css('display', 'flow-root').html(address);
                } else {
                    jQuery('#jform_snprofile_address-lbl').parents(':eq(1)').show();
                    jQuery('#jform_snprofile_postal-lbl').parents(':eq(1)').show();
                    jQuery('#jform_snprofile_postal_code, #jform_snprofile_housenumber').css('border-bottom', 'solid 1px #cdcdcd');
                    jQuery('.address-success').css('display', 'none').html('');
                    jQuery('#address-notfound').removeClass('hide')
                }
            }
        }).fail(function(){
            return false;
        });
    } else {
        jQuery('#jform_snprofile_postal_code, #jform_snprofile_housenumber').css('border-bottom', 'solid 1px #cdcdcd');
        jQuery('#address-empty').removeClass('hide')
    }

    jQuery('.edit-address').on('click', () => {
        jQuery('.address-success').css('display', 'none').html('');
        jQuery('#jform_snprofile_address-lbl').parents(':eq(1)').show();
        jQuery('#jform_snprofile_postal-lbl').parents(':eq(1)').show();
    });
}
