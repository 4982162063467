export default function initTogglePasswordVisibility(input, topOffset = null, rightOffset = null) {
    const $input = jQuery(input);
    if (!$input.length) {
        return;
    }
    const $inputWrapper = $input.parent();
    const toggleButton = '<button type="button" class="toggle-password"> <i class="fa fa-eye-slash"></i></button>';
    if (!topOffset) {
        topOffset = parseInt($input.position().top - $inputWrapper.position().top);
    }
    if (!rightOffset) {
        rightOffset = parseInt(($inputWrapper.offset().left + $inputWrapper.width()) - ($input.offset().left + $input.width())) - 26;
    }

    $input
        .attr('style', 'padding-right:36px!important') // can't use .css() with the !important param
        .after(toggleButton);

    $inputWrapper
        .css('position', 'relative')
        .find('button.toggle-password')
            .css('top', `${topOffset}px`)
            .css('right', `${rightOffset}px`)
            .on('click', (el) => {
                jQuery(el.target).toggleClass('fa-eye').toggleClass('fa-eye-slash');
                if ($input.prop('type') === 'password') {
                    $input.prop('type', 'text');
                } else {
                    $input.prop('type', 'password');
                }
            });
}
