export function processPaymentVipps(form, $submitButton, paymentUrl) {
    const iFrameContainerId = "vipps-checkout-frame-container"
    $submitButton.prop('disabled', true).addClass('button-loading-payment')
    fetch(paymentUrl, {
        method: "POST",
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: jQuery(form).find('input[name!=task]').serialize()
    }).then(response => {
        $submitButton.prop('disabled', false).removeClass('button-loading-payment')
        jQuery('.alert-vipps-error').remove()
        return response.json();
    }).then(responseData => {
        const data = responseData.data || {};
        if (data.checkoutFrontendUrl && data.token) {
            VippsCheckout({
                checkoutFrontendUrl: data.checkoutFrontendUrl,
                iFrameContainerId: iFrameContainerId,
                language: "no",
                token: data.token,
            });
            addCancelPaymentButton(form, iFrameContainerId)
            jQuery(form).slideUp(500);
        } else {
            const errorAlert = parseVippsObjectErrorAlert(responseData)
            jQuery(form).prepend(errorAlert)
        }
    }).catch(error => {
        const errorAlert = getErrorAlertHtml(error)
        jQuery(form).prepend(errorAlert)
    });
}

function addCancelPaymentButton(form, iFrameContainerId) {
    jQuery('#vipps-checkout-frame-container').after(`<div class="cancel-payment-vipps-wrapper"><a href="#" class="cancel-payment-vipps" id="cancelVippsPayment">${Joomla.JText._('COM_SNV2_PAYMENT_CANCEL')}</a></div>`)
    jQuery('#cancelVippsPayment').on('click', e => {
        e.preventDefault()
        cancelVippsPayment(form, iFrameContainerId)
    })
}

function cancelVippsPayment(form, iFrameContainerId) {
    location.reload();
}

function getErrorAlertHtml(error) {
    return `<div class="alert alert-danger alert-vipps-error">${error}</div>`
}

function parseVippsObjectErrorAlert(response) {
    const errorsList = response.messages && response.messages.error
        ? Object.values(response.messages.error).filter(error => error != '')
        : [response.data]

    const html = `<ul><li>${errorsList.join('</li><li>')}</li><ul>`
    return getErrorAlertHtml(html)
}
